.modal {
  padding: 30px;
  background-color: #f3f4f9;

  .top {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 600;
    margin: 0;
    margin-bottom: 5px;
  }

  .logoutPopup {
    h3 {
      text-align: center;
      color: gray;
      font-weight: 500;
      margin-top: 10px;
    }
  }

  .btnsAlignments {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 35px;
    gap: 13px;

    a,
    button {
      background: #7f8699;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      border: none;
      min-width: 150px;
      height: 40px;
      padding: 0px 15px;
      font-size: 15px;
      line-height: 40px;
      color: #fff;
    }

    button {
      background-color: crimson;
    }
  }

}

.modalHeading_close {
  :global(.btn-close) {
    background-color: #cbcec6;
    opacity: 1;
    border-radius: 50%;
    padding: 9px;
  }
}

:global(.catContainer) {
  height: 180px;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 3px;
    /* width of the scrollbar */
  }

  &::-webkit-scrollbar-thumb {
    background-color: black;
    /* color of the thumb */
    border-radius: 5px;
    /* rounded corners */
  }

  &scrollbar {
    width: 4px;
    /* width of the scrollbar */
  }

  &scrollbar-thumb {
    background-color: black;
    /* color of the thumb */
    border-radius: 5px;
    /* rounded corners */
  }

  :global(.item) {
    align-items: center;
    border: 1px solid #3e8575;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    gap: 13px;
    margin-bottom: 15px;
    padding: 25px 20px;
    position: relative;

    &:global(.active) {
      border: 2px solid #65beac;
      box-shadow: none;
      padding: 24px 20px;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        height: 24px;
        width: 24px;
        top: 0;
        right: 0;
        background-image: url("../../images/Tick.svg");
        transform: translate(40%, -40%);
      }

      span {
        color: #3e8575;
      }

      img {
        filter: invert(47%) sepia(45%) saturate(407%) hue-rotate(116deg) brightness(87%) contrast(92%);
      }
    }

    span {
      color: #7f8699;
    }
  }
}