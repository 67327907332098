.loginSection {
  padding: 100px 0px;

  .title {
    margin-bottom: 40px;
    text-align: center;
    font-weight: 600;
    font-size: 34px;

    &.v2 {
      margin: 0;
    }
  }

  span {
    color: var(--Main2);
    text-align: center;
    display: block;
  }

  .loginBg {
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
  }

  .modalContent {
    padding: 50px 0px;

    .modalCenter {
      position: relative;
      z-index: 1;
      .formBody {
        background: #f3f4f9;
        width: 650px;
        margin: 0 auto;
        padding: 30px 30px;
        border-radius: 20px;
        box-shadow: 0 0 3px #00ffff94;
        z-index: 1;
        display: flex;
        align-items: center;
        min-height: calc(100% - var(--bs-modal-margin) * 2);

        :global(.inputDiv) {
          background-color: transparent;
          height: 60px;
          text-align: center;
          font-size: 28px;
        }

        ::-webkit-inner-spin-button {
          display: none;
        }

        .facebookSignup {
          background: #1a77f2;
          border: 1px solid #1a77f2;
          color: #fff;
          width: 100%;
          height: 50px;
          border-radius: 10px;
          position: relative;
          text-align: left;
          padding-left: 45px;
          font-weight: 500;
          margin-top: 10px;

          img {
            height: 22px;
            position: absolute;
            left: 20px;
          }
        }

        .googleSignup {
          background: #e9f6f1;
          color: #132326;
          border: 1px solid #65beac;
          width: 100%;
          height: 50px;
          border-radius: 10px;
          position: relative;
          text-align: left;
          padding-left: 55px;
          font-weight: 500;
          margin-top: 10px;

          img {
            height: 22px;
            position: absolute;
            left: 20px;
          }
        }

        .alreadyBox {
          display: block;
          color: #3c746d;
          text-align: center;
          margin-top: 30px;
          font-weight: 500;
        }

        .forgotBox {
          display: block;
          color: #1ab192;
          text-align: end;
          margin-bottom: 10px;
          font-weight: 500;
          text-decoration: none;
        }

        .successfulBox {
          text-align: center;
          img {
            height: 135px;
            margin-top: 30px;
            margin-bottom: 45px;
          }
          h2 {
            font-size: 34px;
            font-weight: 600;
          }
          p {
            color: #7f8699;
            font-size: 18px;
          }
        }
      }
    }
  }
}
.button {
  // background: #7f8699;
  background-image: linear-gradient(to top,#3e8575,#65beac) !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  border: none;
  min-width: 100%;
  height: 40px;
  padding: 0px 15px;
  font-size: 15px;
  line-height: 40px;
  margin-top: 10px;
  // &:hover {
  //   background-color: none;
  //   background-image: linear-gradient(to top,#3e8575,#65beac) !important;
  // }
}
.password {
  div {
    input {
      padding-left: 0.95rem !important;
    }
  }
}

.successfulBox {
  h2 {
    text-align: center;
    margin: 20px 0;
  }
  p {
    text-align: center;
    margin-bottom: 20px;
  }
}

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/

@media (max-width: 991px) {
  .loginSection {
    .loginBg {
      height: 100%;
    }

    .modalContent {
      padding: 30px 20px;

      .modalCenter {
        .formBody {
          width: 100%;
          padding: 20px 20px;
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
}
