:global(.errorMessage) {
    color: red !important;
    font-size: 13px;
    margin: 2px 0px 0px 2px;
    margin-top: 2px;
    position: relative;
}

:global(.out) {
    position: absolute;
    bottom: 0;
    transform: translateY(20px);
}

:global(.up) {
    transform: translateY(-85px);
}

:global(.v2) {
    width: 95%;
    margin: 0 auto;
    transform: translateY(-13px);
}

body {
    background-color: #eff0f5 !important;
    margin: 0;
    padding: 0;
    font-size: 16px;
    color: #222;
    width: 100%;
    height: 100%;
    font-weight: normal;
    font-style: normal;
    letter-spacing: -0.5px;
    font-family: "Poppins" !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
    /* Firefox */
    appearance: textfield;
}

:global(.form-control)::placeholder {
    color: #bfbfbf;
}

:global(.form-control):-ms-input-placeholder {
    color: #bfbfbf;
}

:global(.form-control)::-ms-input-placeholder {
    color: #bfbfbf;
}

select:required:invalid {
    // color: #bfbfbf !important;
    color: #212529 !important;
}

option {
    color: black;
}

a {
    &:hover {
        color: var(--Main2);
    }
}

:global(.errorBorder) {
    border: 1px solid red;
}

:global(.grayout) {
    opacity: 0.7;
}


.switch {
    position: relative;
    vertical-align: top;
    width: 100px;
    height: 27px;
    background: #fff;
    cursor: pointer;
    box-sizing: content-box;
}

.buttonflexDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    .threeButton {
        background-color: #fff;
        border-radius: 12px;
        margin: 0;

        li {
            padding: 3px;
            margin: 0 !important;

            .btn {
                color: #509F8E;
                background-color: #fff;
                font-size: 14px;
                font-weight: 500;

                border: none;
                border-radius: 12px;
                padding: 10px 30px;


                &.active {
                    background-image: linear-gradient(#65BEAC, #3E8575) !important;
                    color: #fff !important;
                }
            }
        }
    }
}

.switchInput {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    box-sizing: content-box;
}

.switchLabel {
    position: relative;
    display: block;
    height: inherit;
    font-size: 12px;
    background-image: linear-gradient(#eceeef, #eceeef);
    box-sizing: content-box;
    border: 1px solid #e4dcdc;
    overflow: hidden;
    border-radius: 5px;
}

.switchLabel:before,
.switchLabel:after {
    position: absolute;
    top: 50%;
    margin-top: -0.5em;
    line-height: 1;
    -webkit-transition: inherit;
    -moz-transition: inherit;
    -o-transition: inherit;
    transition: inherit;
    box-sizing: content-box;
}

.switchLabel:before {
    content: attr(data-off);
    right: 11px;
    color: #aaaaaa;
    text-shadow: 0 1px rgba(255, 255, 255, 0.5);
}

.switchLabel:after {
    content: attr(data-on);
    left: 11px;
    color: #ffffff;
    text-shadow: 0 1px rgba(0, 0, 0, 0.2);
    opacity: 0;
}

.switchInput:checked~.switchLabel {
    background-image: linear-gradient(var(--Main2), #3e8575);
}

.switchInput:checked~.switchLabel:before {
    opacity: 0;
}

.switchInput:checked~.switchLabel:after {
    opacity: 1;
}

.switchHandle {
    position: absolute;
    top: 3px;
    left: 3px;
    width: 23px;
    height: 23px;
    background: #d8d8d8;
    border-radius: 5px;
}

.switchHandle:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -6px 0 0 -6px;
    width: 12px;
    height: 12px;
    background: #d8d8d8;
    border-radius: 6px;
}

.switchInput:checked~.switchHandle {
    left: 74px;
    background: #fff;
}

.switchInput:checked~.switchHandle:before {
    background: #fff;
}

/* Transition
========================== */
.switchLabel,
.switchHandle {
    transition: All 0.3s ease;
    -webkit-transition: All 0.3s ease;
    -moz-transition: All 0.3s ease;
    -o-transition: All 0.3s ease;
}

.radio {
    position: relative;
    margin-right: 30px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 7px 0px 0px 40px;

    &:hover input~.checkmark {
        background-color: #f3f1f1;
        border: 1px solid #f3f1f1;
    }

    input {
        opacity: 0;
        margin-right: 5px;
        position: absolute;

        &:checked~.checkmark:after {
          background-image: linear-gradient(#65BEAC, #3e8575);
        }

        &:checked~.radio {
            font-weight: 800;
        }
    }

    .checkmark {
        position: absolute;
        top: 5px;
        left: 0;
        height: 28px;
        width: 28px;
        background-image: linear-gradient(to right, #f3f4f9, #f3f4f9);
        border-radius: 50%;
        border: 1px solid #eee;
        box-shadow: -4px -2px 4px 0px #fff, 4px 2px 8px 0px #d1d9e6;

        &:after {
            content: "";
            position: absolute;
            left: 50%;
            top: 50%;
            width: 20px;
            transform: translate(-50%, -50%);
            height: 20px;
            border-radius: 50%;
            background: white;
            background-image: linear-gradient(to right, #ffffff, #dae0ee);
            box-shadow: -4px -2px 4px 0px #d1d9e6, 4px 2px 8px 0px #fff;
        }
    }
}

.checkbox {
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding-left: 25px;
    margin-top: 35px;

    &:hover input~.checkmark {
        background-color: #fff;
        border: 1px solid #3e8575;
    }

    input {
        opacity: 0;
        margin-right: 10px;
        position: absolute;

        &:checked~.checkmark {
            border: 1px solid #3e8575;
        }

        &:checked~.checkmark:after {
            display: block;
        }
    }

    .checkmark {
        position: absolute;
        top: 0px;
        left: 0;
        height: 25px;
        width: 25px;
        background-color: #fff;
        border-radius: 6px;
        border: 1px solid #3e8575;

        &:after {
            content: "";
            position: absolute;
            display: none;
            left: 9px;
            top: 5px;
            width: 6px;
            height: 12px;
            border: solid #3e8575;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
}

.galleryBox {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    gap: 10px;
    margin-top: 10px;
    margin-bottom: 30px;

    .item {
        height: 85px;
        width: 85px;
        border-radius: 10px;
        overflow: hidden;
        position: relative;
        border: 1px solid #dfdbdb;

        .deleteCross {
            position: absolute !important;
            top: 3px;
            right: 3px;
            cursor: pointer;
            border-radius: 50%;
            background-color: #fff;
            padding: 5px;
        }

        &.add {
            border: 3px solid var(--Main2);
            cursor: pointer;
        }

        .bgImage {
            height: 100%;
            width: 100%;
        }

        label {
            height: 100%;
            background-color: #3e8575;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            .add {
                height: 50px;
                width: 50px;
                margin: 0 auto;
                cursor: pointer;
            }

            input[type="file"] {
                display: none;
            }
        }
    }
}

// Define custom variables for colors and dimensions
$muiSwitchWidth: 48px;
$muiSwitchThumbSize: 20px;
$muiSwitchThumbTranslate: $muiSwitchWidth - $muiSwitchThumbSize - 2;

.greenSwitch {
    position: relative;
    width: 52px;
    height: 28px;
    padding: 5px 9px;
    box-shadow: 1.2075471878051758px 1.2075471878051758px 3px 0px rgba(74, 81, 91, 45%);
    border-radius: 100px;
    cursor: pointer;
    overflow: hidden;

    .toggleInput {
        display: none;
    }

    .toggleLabel {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    // Styles for the thumb (the circular part) of the switch
    .toggleThumb {
        position: absolute;
        top: 4px;
        left: 4px;
        padding: 10px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
        transition: transform 0.3s ease;
    }

    .toggleInput:checked~.toggleThumb {
        transform: translateX($muiSwitchThumbTranslate ); // Move the thumb to the right when checked
    }
}

.listUl {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    margin-top: 3px;

    li {
        margin-right: 20px;
    }
}

.iconSelectList {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    margin-top: 3px;

    li {
        display: inline-block;
        text-align: center;
        margin-right: 15px;
        margin-bottom: 10px;
        cursor: pointer;
        max-width: 60px;
        min-width: 60px;

        .iconImg {
            border: 1px solid #d5d7dd;
            background: #eff0f5;
            width: 60px;
            text-align: center;
            height: 60px;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                height: 30px;
            }
        }

        h6 {
            font-size: 13px;
            color: #7f8699;
            margin: 5px 0px 0px 0px;
            font-weight: 400;
        }

        &.active {
            width: 60px;
            min-width: 60px;

            .iconImg {
                border: 1px solid #1ab192;
                background: #e9f5f1;

                img {
                    filter: invert(47%) sepia(45%) saturate(407%) hue-rotate(116deg) brightness(87%) contrast(92%);
                }
            }

            h6 {
                color: #3e8575;
                font-weight: 600;
                font-size: 12px;
                // word-wrap: break-word;
            }
        }
    }
}

.titleBox {
    .title {
        font-size: 30px;
        font-weight: 600;
    }
}

.sectionPadding {
    padding: 80px 0px;
}

.sectionPaddingBottom {
    padding-bottom: 80px;
}

.formBox {
    width: 100%;
    margin-bottom: 15px;

    .listUl {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        margin-top: 3px;
      
        li {
          margin-right: 20px;
        }
      }

    .btnsGroup {
        display: flex;
        justify-content: center;
        margin-top: 20px;
    
        :global(.btn) {
          font-weight: 600;
          padding: 0px 15px;
          font-size: 16px;
          border-radius: 8px;
          height: 45px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-decoration: none;
          min-width: 250px;
          margin: 0px 10px;
          border: none;
          box-shadow: 0px 4px 4px 0px #00000026;
    
          &.btn2 {
            background: #7f8699;
            color: #fff;
          }
    
          &.btn1 {
            color: #7f8699;
            background: #eff0f5;
          }
    
          &.btn3 {
            color: #fff;
            background-image: linear-gradient(var(--Main2), var(--Main3));
            width: 100%;
            margin: 0px;
            height: 50px;
          }
    
          &.btn4 {
            color: #fff;
            background-image: linear-gradient(var(--Main2), var(--Main3));
            height: 47px;
          }
        }
      }
    

    :global(.form-label) {
        font-size: 14px;
        margin-bottom: 5px;
        font-weight: 600;

        .required {
            color: red;
        }
    }

    :global(.form-control) {
        background-color: #eff0f5;
        border: 1px solid #eff0f5;
        padding: 3px 13px;
        font-size: 14px;
        border-radius: 6px;
        height: 38px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);


        &:focus {
            box-shadow: none;
            background-color: #fff;
            border: 1px solid var(--Main2);
        }

        &.removeArrow {

            &::-webkit-outer-spin-butto,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }
    }

    :global(.form-select) {
        background-color: #eff0f5;
        border: 1px solid #eff0f5;
        // padding: 10px 13px;
        // font-size: 14px;
        // height: 45px;
        padding: 3px 13px;
        font-size: 14px;
        height: 38px;
        border-radius: 5px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
        background-position: 96% 50%;

        &:focus {
            box-shadow: none;
            border: 1px solid #fbbf15;
        }

        &.removeArrow {

            &::-webkit-outer-spin-butto,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }
    }

    .eyeIcon {
        position: absolute;
        right: 3px;
        top: 24px;
        transform: translate(-50%, -50%);
        font-size: 20px;
        color: #686868;
        cursor: pointer;
    }

    &.iconFormBox {
        position: relative;

        .icon {
            position: absolute;
            top: 50%;
            left: 12px;
            transform: translate(0%, -50%);
            width: 20px;
            text-align: center;
            height: 100%;
            color: #3d4755;
        }

        .unseenIcon {
            position: absolute;
            top: 50%;
            right: 12px;
            transform: translate(0%, -50%);
            width: 20px;
            text-align: center;
            height: 100%;
            color: #3d4755;
            cursor: pointer;
        }

        :global(.form-control) {
            padding-left: 40px;
            border: 1px solid #7f8699;
            background: #fff;
            box-shadow: none;

            &:focus {
                box-shadow: none;
                background-color: #fff;
                border: 1px solid var(--Main2);
            }
        }
    }

    .btnsGroup {
        display: flex;
        justify-content: center;
        margin-top: 20px;

        :global(.btn) {
            font-weight: 600;
            padding: 0px 15px;
            font-size: 16px;
            border-radius: 8px;
            height: 45px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            min-width: 250px;
            margin: 0px 10px;
            border: none;
            box-shadow: 0px 4px 4px 0px #00000026;

            &.btn2 {
                background: #7f8699;
                color: #fff;
            }

            &.btn1 {
                color: #7f8699;
                background: #eff0f5;
            }

            &.btn3 {
                color: #fff;
                background-image: linear-gradient(var(--Main2), #3e8575);
                width: 100%;
                margin: 0px;
                height: 50px;
            }

            &.btn4 {
                color: #fff;
                background-image: linear-gradient(#65BEAC, #3e8575);
                height: 47px;
            }
        }
    }

    .telBox {
        :global(.PhoneInput) {
            padding-left: 40px;
            border: 1px solid #7f8699;
            background: #fff;
            box-shadow: none;
            padding: 10px 15px;
            font-size: 14px;
            border-radius: 8px;
            height: 45px;

            :global(.PhoneInputInput) {
                width: 100% !important;
                height: auto !important;
                background: transparent;
                border: none;

                &:focus-visible {
                    border: none;
                    box-shadow: none;
                    outline: none;
                }

                &:focus {
                    border: none;
                }
            }

            :global(.flag-dropdown) {
                background: transparent !important;
                border: none !important;

                &:global(.open) {
                    background: transparent;
                }

                :global(.selected-flag) {
                    width: 60px;
                    padding-left: 12px;
                    background: transparent !important;

                    &:hover,
                    &:focus {
                        background: transparent;
                    }

                    &:global(.open) {
                        background: transparent;
                    }
                }

                :global(.country-list) {

                    :global(.country) {

                        span {
                            display: inline-block;
                        }
                    }
                }
            }
        }
    }

    .phoneBox {
        :global(.react-tel-input) {

            :global(.form-control) {
                width: 100% !important;
                padding-left: 45px;
                height: auto !important;
                border-radius: 8px !important;
                background: #eff0f5;
                border: none;

                &:focus {
                    border: none;
                }
            }

            :global(.flag-dropdown) {
                background: transparent !important;
                border: none !important;

                &:global(.open) {
                    background: transparent;
                }

                :global(.selected-flag) {
                    width: 60px;
                    padding-left: 12px;
                    background: transparent !important;

                    &:hover,
                    &:focus {
                        background: transparent;
                    }

                    &:global(.open) {
                        background: transparent;
                    }
                }

                :global(.country-list) {

                    :global(.country) {

                        span {
                            display: inline-block;
                        }
                    }
                }
            }
        }

        :global(.PhoneInput) {
            background-color: #eff0f5;
            border: 1px solid #eff0f5;
            padding: 0 15px;
            font-size: 14px;
            border-radius: 8px;
            height: 38px;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);

            :global(.PhoneInputInput) {
                width: 100% !important;
                height: auto !important;
                background: #eff0f5;
                border: none;

                &:focus-visible {
                    border: none;
                    box-shadow: none;
                    outline: none;
                }

                &:focus {
                    border: none;
                }
            }

            :global(.flag-dropdown) {
                background: transparent !important;
                border: none !important;

                &:global(.open) {
                    background: transparent;
                }

                :global(.selected-flag) {
                    width: 60px;
                    padding-left: 12px;
                    background: transparent !important;

                    &:hover,
                    &:focus {
                        background: transparent;
                    }

                    &:global(.open) {
                        background: transparent;
                    }
                }

                :global(.country-list) {

                    :global(.country) {

                        span {
                            display: inline-block;
                        }
                    }
                }
            }
        }
    }

    .booking {
        :global(.css-b62m3t-container) {
            :global(.css-1jqq78o-placeholder) {
                color: #65beac;
            }

            :global(.css-13cymwt-control),
            :global(.css-t3ipsp-control) {
                background-color: #fff;
                border: 1px solid #fff;
                padding: 3px 5px;
                font-size: 14px;
                border-radius: 5px;
                box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
                min-height: 42px;
                height: auto;

                :global(.css-1hb7zxy-IndicatorsContainer) {
                    :global(.css-1u9des2-indicatorSeparator) {
                        display: none;
                    }

                    :global(.css-1xc3v61-indicatorContainer) {
                        color: #4e4e4e;
                    }
                }

                :global(.css-3w2yfm-ValueContainer),
                :global(.css-1dyz3mf) {

                    :global(.css-1p3m7a8-multiValue) {
                        background-image: linear-gradient(var(--Main2), #3e8575);
                        color: #fff;

                        :global(.css-wsp0cs-MultiValueGeneric),
                        :global(.css-9jq23d) {
                            color: #fff;
                        }

                        :global(.css-12a83d4-MultiValueRemove),
                        :global(.css-v7duua) {
                            background: transparent;
                            color: #fff;
                        }
                    }
                }
            }
        }
    }

    :global(.css-b62m3t-container) {

        :global(.css-13cymwt-control),
        :global(.css-t3ipsp-control) {
            background-color: #eff0f5;
            border: 1px solid #eff0f5;
            padding: 0px 5px;
            font-size: 14px;
            border-radius: 5px;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);

            :global(.css-1hb7zxy-IndicatorsContainer) {
                :global(.css-1u9des2-indicatorSeparator) {
                    display: none;
                }

                :global(.css-1xc3v61-indicatorContainer) {
                    color: #4e4e4e;
                    padding-right: 3px;
                }
            }

            :global(.css-3w2yfm-ValueContainer),
            :global(.css-1dyz3mf) {

                :global(.css-1p3m7a8-multiValue) {
                    background-image: linear-gradient(var(--Main2), #3e8575);
                    color: #fff;

                    :global(.css-wsp0cs-MultiValueGeneric),
                    :global(.css-9jq23d) {
                        color: #fff;
                    }

                    :global(.css-12a83d4-MultiValueRemove),
                    :global(.css-v7duua) {
                        background: transparent;
                        color: #fff;
                    }
                }
            }
        }
    }
}

.line_bottom {
    h5 {
        border-bottom: 2px solid #eff0f5;
        padding-bottom: 9px;
    }


}

:global(.singleBtn) {
    font-weight: 600;
    padding: 0px 15px;
    font-size: 14px;
    border-radius: 8px;
    color: #fff;
    // height: 45px;
    height: 39px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    min-width: 250px;
    border: none;
    box-shadow: 0 3px 4px rgba(0, 0, 0, 25%);
    background-image: linear-gradient(var(--Main2), #3e8575);
}

:global(.react-tel-input) {

    :global(.form-control) {
        width: 100% !important;
        padding-left: 70px;
        height: auto !important;
        border-radius: 8px !important;

        &:focus {
            border: 1px solid #ccc;
        }
    }

    :global(.flag-dropdown) {
        background: transparent !important;
        border: none !important;

        &:global(.open) {
            background: transparent;
        }

        :global(.selected-flag) {
            width: 60px;
            padding-left: 12px;
            background: transparent !important;

            &:hover,
            &:focus {
                background: transparent;
            }

            &:global(.open) {
                background: transparent;
            }
        }

        :global(.country-list) {

            :global(.country) {

                span {
                    display: inline-block;
                }
            }
        }
    }
}

.editBtn {
    border: none;
    background-color: #fff;
    border-radius: 4px;
    padding: 8px 10px;
    position: absolute;
    right: 0px;
    top: 0px;
    border-radius: 10px;
    z-index: 10;
    transform: translate(-40%, -10%);
    box-shadow: 4px 0px 8px rgba(0, 0, 0, 0.1);

    &:hover {
        opacity: 0.8;
    }
}

.font {
    font-family: "Poppins" !important;
}

.pageWrapper {
    margin-left: 60px;
    background: #eff0f5;
    padding: 25px 25px 40px 25px;
    transition: all 0.3s;

    &.pageWrapperWebsite {
        margin-left: 0;
    }

    .pageTitle {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .titleInfo {
            display: flex;
            width: 100%;

            h5 {
                margin: 0px;
                font-size: 20px;
                color: #525c62;
            }

            .icon {
                margin-right: 10px;
            }
        }

        .rightInfo {
            width: 100%;
            display: flex;
            justify-content: end;

            .searchBox {
                position: relative;
                width: 300px;

                :global(.form-control) {
                    padding: 10px 15px;
                    font-size: 14px;
                    box-shadow: 1px 2px 3px #062e241a;
                    border-radius: 10px;
                    border: none;
                    height: 45px;
                }

                .searchBtn {
                    position: absolute;
                    right: 2px;
                    top: 50%;
                    background: transparent;
                    border: none;
                    padding: 0px;
                    transform: translate(-50%, -50%);

                    img {
                        height: 18px;
                    }
                }
            }

            .filterBox {
                background-image: linear-gradient(var(--Main2), #3e8575);
                border: none;
                width: 45px;
                height: 45px;
                padding: 0px;
                border-radius: 10px;
                margin-left: 15px;
                box-shadow: 1px 2px 3px #062e241a;

                img {
                    height: 16px;
                }
            }
        }
    }

    .buttonsAction {
        margin-top: 20px;

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            display: flex;
            justify-content: end;
            flex-wrap: wrap;
            row-gap: 10px;

            li {
                margin-left: 10px;

                .whiteBtn {
                    background: #fff;
                    box-shadow: 1px 2px 3px rgba(6, 46, 36, 0.1019607843);
                    color: #7f8699;
                    border-radius: 10px;
                    border: none;
                    font-weight: 500;
                    padding: 0px 15px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 45px;
                    position: relative;
                    overflow: hidden;

                    img {
                        margin-right: 8px;
                    }

                    input[type="file"] {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        cursor: pointer;
                        opacity: 0;
                    }
                }

                .darkBtn {
                    background-image: linear-gradient(var(--Main2), #3e8575);
                    color: #fff;
                    border-radius: 10px;
                    border: none;
                    font-weight: 500;
                    padding: 0px 15px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 45px;

                    img {
                        margin-right: 8px;
                        filter: invert(8%) sepia(13%) saturate(2397%) hue-rotate(60deg) brightness(665%) contrast(360%);
                    }
                }
            }
        }
    }

    .tableBody {
        position: relative;

        &::after {
            content: "";
            position: absolute;
            left: 50%;
            width: 98%;
            height: 20px;
            background: #b8dcd8;
            bottom: -10px;
            transform: translate(-50%, 0%);
            border-radius: 0px 0px 20px 20px;
        }

        .tableBodyIn {
            background: #fff;
            padding: 15px 17px 25px 35px;
            border-radius: 20px;
            margin-top: 20px;
            position: relative;
            z-index: 1;

            &::before {
                content: "";
                position: absolute;
                left: 0;
                width: 18px;
                height: 100%;
                background-image: linear-gradient(to right, #3e8575, var(--Main2));
                top: 0;
                border-radius: 20px 0px 0px 20px;
            }
        }
    }

    .tabbingBox {
        margin-top: 20px;
        position: relative;

        :global(.nav) {
            background-color: #fcfdff;
            display: inline-flex;
            border-radius: 20px 20px 0px 0px;

            :global(.nav-item) {
                :global(.nav-link) {
                    background-color: #fcfdff;
                    color: #7f8699;
                    display: flex;
                    align-items: center;
                    padding: 15px 25px 15px 25px;
                    font-size: 14px;
                    font-weight: 400;
                    border-radius: 20px 20px 0px 0px;

                    .active {
                        display: none;
                        margin-right: 5px;
                    }

                    .defalut {
                        margin-right: 5px;
                    }

                    &:global(.active) {
                        background-color: #fff;
                        color: #3e8575;
                        font-weight: 600;
                        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
                        position: relative;
                        border-radius: 20px 20px 0px 0px;
                        top: -5px;
                        padding: 20px 25px 20px 25px;

                        .active {
                            display: block;
                        }

                        .defalut {
                            display: none;
                        }

                        &::after {
                            content: "";
                            position: absolute;
                            bottom: 6px;
                            left: 0;
                            width: 100%;
                            height: 15px;
                            background: #fff;
                            z-index: 2;
                        }
                    }
                }
            }
        }

        :global(.tab-content) {
            border-radius: 20px;
            background: #ffffff;
            padding: 20px 20px;
            box-shadow: 4px 0px 8px rgba(0, 0, 0, 0.1);
            margin-top: -15px;
            z-index: 1;
            position: relative;
            min-height: 500px;
        }

        &::after {
            content: "";
            position: absolute;
            left: 50%;
            width: 98%;
            height: 20px;
            background: #b8dcd8;
            bottom: -10px;
            transform: translate(-50%, 0%);
            border-radius: 0px 0px 20px 20px;
        }
    }

    .deleteBtnIcon {
        text-align: end;
        margin-top: 6px;

        button {
            background: transparent;
            border: none;

            img {
                filter: grayscale(1);
            }
        }
    }

    .deleteBtnGap {
        margin-top: 29px;
    }
}


.sliderRow {

    :global(.owl-item) {

        :global(.item) {
            padding: 0px 10px;
        }
    }

    :global(.owl-nav) {

        button {
            position: absolute;
            top: -70px;
            margin: 0px !important;

            &:hover {
                background: transparent !important;
            }
        }

        :global(.owl-prev) {
            left: 0;

        }

        :global(.owl-next) {
            right: 0;
        }
    }
}


.radiusSlider {

    :global(.MuiSlider-root) {

        :global(.MuiSlider-rail) {
            color: #3C746D;
        }

        :global(.MuiSlider-track) {
            color: #3C746D;
        }

        :global(.MuiSlider-thumb) {
            width: 16px;
            height: 16px;

            &:before {
                background-image: linear-gradient(to right, var(--Main2), #3e8575);
            }

            :global(.MuiSlider-valueLabelOpen) {
                top: 50px;
                background: transparent;

                &:before {
                    content: "";
                    display: none;
                }

                :global(.MuiSlider-valueLabelCircle) {

                    :global(.MuiSlider-valueLabelLabel) {
                        font-size: 16px;
                        color: var(--Main2);

                        &::after {
                            content: " m";
                        }
                    }
                }
            }
        }
    }
}

// sub side bar page wrapper style 
:global(.activesubsidebar) {
    margin-left: 285px;
}


// .editorContainer {
//     min-height: 200px;
//     height: auto;
//     margin-bottom: 25px;

//     :global(.quill) {
//         height: 150px;

//         :global(.ql-toolbar) {
//             background-color: #eff0f5;
//             border: 1px solid red solid #eff0f5;
//             padding: 10px 10px;
//             font-size: 14px;
//             border-radius: 8px 8px 0 0;
//             box-shadow: 0 1px solid red 3px rgba(0, 0, 0, 0.25);
//         }

//         :global(.ql-container) {
//             background-color: #eff0f5;
//             border: 1px solid red solid #eff0f5;
//             padding: 0px 3px;
//             font-size: 14px;
//             border-radius: 0 0 8px 8px;
//             box-shadow: 0 1px solid red 3px rgba(0, 0, 0, 0.25);
//         }
//     }
// }



.editorBox {
    position: relative;
    margin-top: 20px;
  
    :global(.accordion) {
      :global(.accordion-item) {
        background-color: #eff0f5;
      }
    }
  }
  
  .editorContainer {
    min-height: 200px;
    height: auto;
  
    // margin-bottom: 35px;
  
    :global(.quill) {
      height: 130px;
  
      :global(.ql-toolbar) {
        background-color: #eff0f5;
        border: 1px solid red solid #eff0f5;
        padding: 10px 10px;
        font-size: 14px;
        height: 62px;
        box-shadow: 0 1px solid red 3px rgba(0, 0, 0, 0.25);
      }
  
      :global(.ql-container) {
        background-color: #eff0f5;
        border: none;
        padding: 0px 3px;
        font-size: 14px;
  
        box-shadow: 0 1px solid red 3px rgba(0, 0, 0, 0.25);
      }
    }
  }

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/

@media (max-width: 767px) {
    .pageWrapper {
        margin-left: 0px;
        padding: 20px 20px;

        .pageTitle {
            display: block;

            .titleInfo {
                display: flex;
                margin-bottom: 10px;
            }

            .rightInfo {
                width: 100%;

                .searchBox {
                    width: 100%;
                }

                .filterBox {
                    min-width: 45px;
                    max-width: 45px;
                }
            }
        }

        .buttonsAction {
            margin-top: 20px;

            ul {
                display: block;

                li {
                    margin-left: 0px;
                    margin-right: 10px;
                    margin-bottom: 10px;
                    display: inline-block;
                }
            }
        }

        .tableBody {
            position: relative;

            &::after {
                content: "";
                position: absolute;
                left: 50%;
                width: 98%;
                height: 20px;
                background: #b8dcd8;
                bottom: -10px;
                transform: translate(-50%, 0%);
                border-radius: 0px 0px 20px 20px;
            }

            .tableBodyIn {
                padding: 20px 15px 15px 20px;
                margin-top: 10px;

                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    width: 10px;
                    height: 100%;
                    background-image: linear-gradient(to right,
                            #3e8575,
                            var(--Main2));
                    top: 0;
                    border-radius: 20px 0px 0px 20px;
                }
            }
        }

        .tabbingBox {
            margin-top: 10px;

            :global(.nav) {
                background-color: #fcfdff;
                display: flex;
                border-radius: 20px 20px 0px 0px;
                flex-wrap: unset;
                white-space: nowrap;
                overflow: scroll;
            }

            :global(.tab-content) {
                border-radius: 0px 0px 20px 20px;
            }
        }
    }

    .iconSelectList {
        display: flex;
        // white-space: nowrap;
        overflow: hidden;
        overflow-x: scroll;
    }

    .listUl {
        margin-top: 0px;
        display: block;

        li {
            margin-right: 0px;
            margin-bottom: 10px;
        }
    }

    .formBox {
        .btnsGroup {
            :global(.btn) {
                min-width: 110px;
            }
        }
    }

    .sliderRow {

        :global(.owl-nav) {

            button {
                position: relative;
                top: 0;
                margin: 0px 20px !important;
            }
        }
    }
}

@media (max-width : 990px) {
    :global(.form-control) {
        font-size: 12px !important;
    }

    :global(.form-select) {
        font-size: 12px !important;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {}

@media only screen and (max-width: 5520px) and (min-width: 768px) {}

@media only screen and (min-width: 1200px) and (max-width: 1279px) {}

/* =======================================================
  //////////////RESPONSIVE MEDIA QUERY END///////////////
  ======================================================= */