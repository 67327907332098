.modalCts {
  :global(.modal-dialog) {
    :global(.modal-content) {
      border: none;
      background-color: #fff;
      border-radius: 20px;

      :global(.modal-body) {
        padding: 50px 30px 26px;
        width: 100%;
        border-radius: 20px;

        .closeIcon {
          position: absolute;
          right: 5px;
          top: 5px;
          background: #f3f4f9;
          border: none;
          width: 40px;
          height: 40px;
          padding: 5px 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50px;
          font-size: 25px;
        }

        .title {
          text-align: center;
          margin-bottom: 30px;

          h5 {
            font-size: 34px;
            font-weight: 600;
            margin: 0;
            text-align: center;
          }
        }
      }

    }
  }

  &.modalBg {
    :global(.modal-dialog) {
      :global(.modal-content) {
        background-color: #ffffff;

        :global(.modal-body) {
          .closeIcon {
            background-color: #fff;
          }

          :global(.form-control),
          :global(.form-select) {
            background-color: #f3f4f9;
            border: 1px solid #f3f4f9;
          }

          .contentTaps {
            background-color: #f3f4f9;
            padding: 25px 25px;
            margin-bottom: 20px;
            border: 1px solid #cdd2de;

            :global(.form-control),
            :global(.form-select) {
              background-color: #ffffff !important;
              border: 1px solid #e3e7ee !important;
            }
          }
        }
      }
    }
  }

  &.modalBusinessDetails {
    :global(.modal-dialog) {
      :global(.modal-content) {
        :global(.modal-body) {
          padding: 0;

          .title {
            background: linear-gradient(#f1f1f1, #dddddd);
            border-radius: 20px 20px 0px 0px;
            color: #000000;
            padding: 15px 20px;
            margin-bottom: 0px;

            h5 {
              font-size: 22px;
            }
          }

          .businessCategory_body {
            padding: 15px 20px;

            .business_mainHeding {
              h3 {
                font-weight: 600;
                margin-bottom: 12px;
              }
            }

            .business_subHeding {
              h6 {
                font-weight: 600;
                color: #323232;
                margin-bottom: 10px;
                margin-top: 0px;

                span {
                  font-weight: 400;

                }
              }
            }

            .business_branchHeading {
              font-weight: 600;
              font-size: 21px;
            }

            hr {
              border-top: 2px solid rgb(77, 77, 77);
            }
          }
        }
      }
    }
  }
}

.resetPopUp {
  :global(.modal-dialog) {
    :global(.modal-content) {

      :global(.modal-body) {
        padding: 0;
        overflow: hidden;
        background: #eeeeee;

        .title {
          background: linear-gradient(180.35deg, #65beac .27%, #3e8575 98.11%);
          padding: 10px 0;
          color: #fff;
          margin-bottom: 0px;

          h5 {
            font-size: 20px;
          }
        }

        .closeIcon {
          right: 9px;
          top: 10px;
          width: 26px;
          height: 26px;
        }

        .reset_Body {
          padding: 30px 30px;

          .succes_mainBox {
            text-align: center;

            .succes_content {
              margin-top: 20px;

              h3 {
                color: #000;
                margin-bottom: 2px;
              }

              p {
                margin-bottom: 0px;
                color: #242424;
              }
            }
          }
        }

        .formField_input {
          :global(.form-control) {
            border: 1px solid #3e8575;
          }

          .add_icon {
            position: relative;

            :global(svg) {
              position: absolute;
              top: 50%;
              right: 9px;
              transform: translate(0%, -50%);
              font-size: 21px;
              cursor: pointer;
              color: #5b5a5a;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 576px) {
  .modalCts {
    :global(.modal-dialog) {
      max-width: 530px;
    }
  }

  .modalCts.resetPopUp {
    :global(.modal-dialog) {
      max-width: 480px;
    }
  }

  .modalSizeLg {
    :global(.modal-dialog) {
      max-width: 860px !important;
    }
  }
}