.colorBox {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .active {
    padding: 3px;
    box-sizing: border-box;
    cursor: pointer;

    &:after {
      content: "";
      padding: 11px;
      border-radius: 50%;
      border: 2px solid #fff;
    }
  }

  li {
    padding: 17px;
    border-radius: 50%;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:first-child {
      padding: none;
    }

    .insideColor {
      height: 25px;
      width: 25px;
      border-radius: 50%;
    }
  }
}
.uploadData {
  h5 {
    font-size: 20px;
    font-weight: 600;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: flex;
      color: #7f8699;
      line-height: 25px;
      font-size: 14px;

      span {
        width: 100px;
      }
      strong {
        color: #1d1d1d;
        font-weight: 600;
      }
      a {
        color: var(--Main);
        text-decoration: none;
      }
    }
  }
}

.addedSocial {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    a {
      display: flex;
      color: var(--Main);
      text-decoration: none;
      align-items: center;
      margin-bottom: 10px;

      .iconBox {
        font-size: 20px;
        margin-right: 10px;
        line-height: 0;
      }
    }
  }
}

.studentsCount {
  display: flex;
  align-items: center;
  justify-content: space-between;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: flex;
      color: #7f8699;
      line-height: 25px;
      font-size: 14px;

      strong {
        color: #1d1d1d;
        font-weight: 600;
      }
    }
  }
}

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .uploadData {
    ul {
      li {
        line-height: 35px;
      }
    }
  }
}
