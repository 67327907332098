
.wrapper {
    height: 100vh;
    max-width: 1920px;
    margin: 0 auto;
    background:var(--BodyBackground);
    border-right: 1px solid #e6e5e5;
}

.wrapperLogin {
    height: 100vh;
    max-width: 1920px;
    margin: 0 auto;
    background:#fff;
    border-right: 1px solid #e6e5e5;
}

.mobileHide {
 display: none;
}
/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/


@media (max-width: 767px) {

    .wrapper {
        border-right:none;
        display: none;
    }
    .mobileHide {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        line-height: 28px;
        font-size: 18px;
        width: 100%;
        display: block;

        p {
            margin-bottom: 0px;
        }
    }

}