:global(.serviceCategories) {
    border: 1px solid var(--Main3);
    padding: 20px;
    display: flex;
    align-items: center;
    gap: 13px;
    border-radius: 10px;
    margin-bottom: 15px;
    position: relative;
    cursor: pointer;
    &.active {
      border: 2px solid var(--Main2);
      box-shadow: none;
      padding: 20px 20px;
      &::after {
        content: "";
        position: absolute;
        height: 24px;
        width: 24px;
        top: 0;
        right: 0;
        background-image: url("../../images/Tick.svg");
        transform: translate(40%, -40%);
      }
    }
    img {
      width: 24px;
      height: 24px;
      &.select {
        filter: invert(47%) sepia(45%) saturate(407%) hue-rotate(116deg)
          brightness(87%) contrast(92%);
      }
    }
    span {
      color: #7f8699;
      &.select {
        color: var(--Main3);
      }
    }
  }