.price_categorySelect {
    display: flex;
    gap: 10px;
    align-items: flex-start;
  
    .displayBox {
      p {
        background-color: #eff0f5;
        border: 1px solid #eff0f5;
        padding: 10px 13px;
        font-size: 14px;
        border-radius: 8px;
        height: 45px;
        padding: 9px 10px;
        margin-bottom: 0px;
        overflow: hidden;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        line-height: 27px;
      }
    }
  
    .eyeModal_relative{
      position: relative;
  
      p{
        padding-right: 34px;
      }
  
      .content_modal{
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;
  
        :global(svg){
          font-size: 18px;
        }
      }
    }
  
    :global(.form-label) {
      font-size: 13px;
      font-weight: 600;
      line-height: 15px;
      height: 24px;
    }
  
    :global(.form-select),
    :global(.form-control) {
      font-size: 13px;
      padding: 9px 10px;
      background-size: 11px;
      height: auto;
    }
  
    .duration_withPrice {
      display: flex;
      background: #eff0f5;
      width: 100%;
      padding: 8px 0px;
      border-bottom: 20px;
      align-items: center;
      border-radius: 6px;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
  
      :global(.form-control) {
        width: 50%;
        background-color: transparent;
        border: none;
        padding: 0;
        text-align: center;
        box-shadow: none;
      }
    }
  
    .edit_delete_btn {
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        margin-top: 0px;
        margin-bottom: 14px;
  
        li {
          :global(.btn) {
            padding: 6px 6px;
  
            img {
              width: 15px;
            }
          }
        }
      }
    }
  
    :global(.plusBtn) {
      background: #479382 !important;
      color: #fff !important;
      padding: 0px 0px;
      width: 45px;
      height: 40px;
      margin-top: 34px;
      font-size: 12px;
    }
  }