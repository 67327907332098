
.editorBox {
    position: relative;
    margin-top: 20px;
  
    :global(.accordion) {
      :global(.accordion-item) {
        background-color: #eff0f5;
      }
    }
  }
  
  .editorContainer {
    min-height: 200px;
    height: auto;
  
    // margin-bottom: 35px;
  
    :global(.quill) {
      height: 130px;
  
      :global(.ql-toolbar) {
        background-color: #eff0f5;
        border: 1px solid red solid #eff0f5;
        padding: 10px 10px;
        font-size: 14px;
        height: 62px;
        box-shadow: 0 1px solid red 3px rgba(0, 0, 0, 0.25);
      }
  
      :global(.ql-container) {
        background-color: #eff0f5;
        border: none;
        padding: 0px 3px;
        font-size: 14px;
  
        box-shadow: 0 1px solid red 3px rgba(0, 0, 0, 0.25);
      }
    }
  }

  