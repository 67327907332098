.chatList {
  background: #eff0f5;
  overflow: hidden;
  height: calc(100vh - 62px);
  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 1px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 50px;
  }

  .chatHeading {
    height: 70.71px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 12;
    background-color: #eff0f5;


    span {
      font-weight: 600;
      font-size: 20px;
      color: #525c62;
    }
  }

  .chatSearchBox {
    position: relative;
    padding: 2px 20px 20px 20px;
    background-color: #eff0f5;
    position: sticky;
    top: 70.71px;
    z-index: 12;

    .searchBtn {
      position: absolute;
      top: 11px;
      left: 30px;
      color: #1ab192;
      height: 20px;
      width: 20px;
    }

    :global(.form-control) {
      color: #1ab192;
      border: none;
      padding: 8px 33px;
      padding-right: 10px;

      &:focus {
        box-shadow: none;
      }

    }
  }

  input[type="search"] {

    /* Hide the default search cancel button */
    &::-webkit-search-cancel-button {
      cursor: pointer;
      filter: invert(51%) sepia(74%) saturate(256%) hue-rotate(116deg) brightness(86%) contrast(89%);
    }
  }




  ul {
    list-style: none;
    margin: 0px;
    padding: 0px;

    li {
      .listMenu {
        display: flex;
        width: 100%;
        border: none;
        box-shadow: none;
        background: #eff0f5;
        text-align: left;
        padding: 13px 10px 13px 18px;
        margin: 0px;
        align-items: center;
        border-bottom: 1px solid #c9c9c9;
        position: relative;
        z-index: 11;

        .businessIconBox {
          min-width: 43px;
          max-width: 43px;
          height: 40px;
          overflow: hidden;
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: linear-gradient(#65BEAC, #3E8575);
          color: #fff;
        }

        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 8px;
          border-radius: 0 8px 8px 0;
          height: 100%;
          background-image: linear-gradient(to right,
              var(--Main2),
              var(--Main3));
          transition: 0.5s;
          opacity: 0;
        }

        &.active {
          background-color: none;
          background: #fff !important;
          outline: none;

          &::before {
            opacity: 1;
          }
        }

        :focus-visible {
          outline: none;
        }



        .dropdownItem {
          position: absolute;
          right: 7px;
          bottom: 9px;

          :global(.btn) {
            border-radius: 0px;
            text-align: left;
            background: transparent;
            color: #000;
            font-size: 14px;
            font-weight: 500;
            border: 0;
            line-height: 0px;
          }

          :global(.dropdown-menu) {
            padding: 5px 0px;
            border: none;
            border-radius: 8px;
            box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);

            ul {
              list-style: none;
              margin: 0;
              padding: 0;

              li {
                .menu {
                  text-decoration: none;
                  margin: 0px;
                  display: block;
                  width: 100%;
                  padding: 6px 15px;
                  border-bottom: 1px solid #c7c7c7;
                  color: #333;
                  font-size: 14px;
                }

                &:last-child {
                  .menu {
                    border-bottom: none;
                  }
                }
              }
            }
          }
        }

        .profileImg {
          min-width: 43px;
          max-width: 43px;
          height: 40px;
          border-radius: 8px;
          object-fit: cover;
        }

        /* Add this to your CSS file (index.module.scss) */
        .chatTextarea {
          resize: none;
          overflow: hidden;
          width: 100%;
          box-sizing: border-box;
          padding: 10px;
          font-size: 14px;
          line-height: 20px;
          border-radius: 10px;
          border: 1px solid #ccc;
        }

        .chatTextarea:focus {
          outline: none;
          border-color: #65beac;
        }

        .chatTextareaWrapper {
          position: relative;
          width: 100%;
        }

        .chatListBody_mainBox {
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;

          .chating_dateTime {
            white-space: nowrap;
            font-size: 10px;
          }

          .chatListBody {
            display: flex;
            width: 100%;
            justify-content: space-between;
            gap: 15px;
            padding: 0px 8px;
            padding-right: 16px;
            position: relative;

            h5 {
              font-size: 13px;
              margin-bottom: 3px;
              overflow: hidden;
              -o-text-overflow: ellipsis;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;


            }

            span {
              color: rgb(62, 133, 117);
              font-weight: 400;
              text-wrap: nowrap;
              font-size: 10px;
            }

            

            .timeBox {
              position: absolute;
              right: 0px;
              top: 0px;
              text-align: right;
              z-index: 9;

              :global(.badge) {
                font-weight: normal;
                width: 20px;
                height: 20px;
                display: flex;
                align-items: center;
                border-radius: 50px;
                justify-content: center;
                float: right;
                background: var(--Main) !important;
              }
            }

            .status {
              width: 10px;
              height: 10px;
              background: #0c9b7e;
              display: flex;
              position: absolute;
              right: 0;
              bottom: 2px;
              border-radius: 50px;
            }
          }
          p {
            margin: 0px;
            overflow: hidden;
            -o-text-overflow: ellipsis;
            text-overflow: ellipsis;
            // display: -webkit-box;
            text-wrap: nowrap;
            white-space: nowrap;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            color: #7f8699;
            font-size: 12px;
            padding: 0px 8px;
          }
        }


      }
    }

    .dropdownList {
      &.dropDownRemove {
        :global(.btn) {
          &::after {
            display: none;
          }
        }
      }

      :global(.btn) {
        width: 100%;
        border-radius: 0px;
        text-align: left;
        background: transparent;
        border: none;
        border-bottom: 1px solid #c9c9c9;
        color: #000;
        font-size: 14px;
        font-weight: 500;

        &::after {
          position: absolute;
          right: 5px;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }

      :global(.dropdown-menu) {
        padding: 5px 0px;
        border: none;
        border-radius: 8px;
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);

        ul {
          list-style: none;
          margin: 0;
          padding: 0;

          li {
            .menu {
              text-decoration: none;
              margin: 0px;
              display: block;
              width: 100%;
              padding: 6px 15px;
              border-bottom: 1px solid #c7c7c7;
              color: #333;
              font-size: 14px;
            }

            &:last-child {
              .menu {
                border-bottom: none;
              }
            }
          }
        }
      }
    }
  }
}

.chatbody {
  .chatHead {
    background: #eff0f5;
    margin: 0px;
    border-bottom: 1px solid #c9c9c9;
    padding: 15px 10px;
    align-items: center;

    .userDetails {
      display: flex;
      width: 100%;
      border: none;
      box-shadow: none;
      text-align: left;
      margin: 0px;
      align-items: center;
      position: relative;

      .emptyImageBox {
        width: 56px;
        height: 40px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(#65BEAC, #3E8575);
        color: #fff;
      }

      .profileImg {
        width: 40px;
        height: 40px;
        border-radius: 8px;
        object-fit: cover;
      }

      .chatListBody {
        width: 100%;
        padding: 0px 8px;
        padding-right: 6px;
        position: relative;

        h5 {
          overflow: hidden;
          -o-text-overflow: ellipsis;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          font-size: 14px;
          margin-bottom: 3px;
        }

        p {
          margin: 0px;
          overflow: hidden;
          -o-text-overflow: ellipsis;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          color: #7f8699;
          font-size: 12px;
        }

        .status {
          span {
            width: 8px;
            height: 8px;
            display: inline-block;
            border-radius: 50px;
            margin-right: 3px;
          }
        }
      }
    }

    .rightTopList {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: end;
      align-items: center;

      li {
        a {
          font-size: 20px;
          margin-right: 28px;
          color: var(--Main3);
        }
      }
    }

    .dropdownItem {
      float: right;

      :global(.btn) {
        border-radius: 0px;
        text-align: left;
        background: transparent;
        color: #7f8699;
        font-size: 25px;
        font-weight: 500;
        border: 0;
        line-height: 0px;
        padding: 0px;

        &::after {
          display: none;
        }
      }

      :global(.dropdown-menu) {
        padding: 5px 0px;
        border: none;
        border-radius: 8px;
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);

        ul {
          list-style: none;
          margin: 0;
          padding: 0;

          li {
            .menu {
              text-decoration: none;
              margin: 0px;
              display: block;
              width: 100%;
              padding: 6px 15px;
              border-bottom: 1px solid #c7c7c7;
              color: #333;
              font-size: 14px;
            }

            &:last-child {
              .menu {
                border-bottom: none;
              }
            }
          }
        }
      }
    }
  }
}

.userchatBody {
  overflow: hidden;
  height: calc(100vh - 211px);
  overflow-x: hidden;
  overflow-y: auto;
  padding: 25px 24px;
  background: #fff;
  .dateHeading {
    text-align: center;
    color: #3e8575;
    font-size: 18px;
    letter-spacing: 0.6px;
    font-weight: 500;
  }

  &::-webkit-scrollbar {
    width: 1px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 50px;
  }

  .oldDated {
    display: inline-block;
    width: 100%;
    text-align: center;
    margin: 20px 0px;

    span {
      background: #8fa0af;
      padding: 8px 15px;
      color: #fff;
      font-size: 14px;
      border-radius: 50px;
    }
  }

  .chatMessage {
    display: flex;
    align-items: end;
    margin-bottom: 15px;

    .pdf_main {
      background: rgba(27, 178, 147, 0.2);
      padding: 14px;
      border-radius: 12px;
      min-width: 116px;
      max-width: 116px;
      // margin-right: 10px;

      .pdf_icon {
        text-align: center;

        :global(svg) {
          font-size: 55px;
        }
      }

      .pdf_details {
        margin-top: 6px;

        .line {
          font-size: 12px;
          font-weight: 500;
          overflow: hidden;
          -o-text-overflow: ellipsis;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }

        .download_withsize {
          display: flex;
          justify-content: space-between;
          margin-top: 4px;
          align-items: center;

          .size {
            font-size: 9px;
            overflow: hidden;
            -o-text-overflow: ellipsis;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            color: #000;
          }

          :global(svg) {
            font-size: 14px;
          }
        }


      }
    }

    .chatImage {
      height: 100px;
      width: 116px;
      border-radius: 12px;
      object-fit: contain;
      cursor: pointer;
      background-color: #eff0f5;
      padding: 12px;
    }

    .profileImg {
      width: 40px;
      height: 40px;
      object-fit: cover;
      border-radius: 8px;
    }

    .chatMessageBody {
      &.loader {
        height: 100px;
        width: 116px;
        border-radius: 12px;
        object-fit: contain;
        cursor: pointer;
        background-color: #eff0f5;
        padding-left: 0 !important;
        display: flex;
        align-items: center;

        .loadingIndicator {
          margin-top: 30px;
        }
      }

      p {
        margin: 0px;
        background-color: #eef1f4;
        padding: 10px 10px 8px 10px;
        border-radius: 10px 10px 10px 0px;
        font-size: 14px;
        position: relative;
      }
    }

    &.chatLeft {
      justify-content: left;

      .chatMessageBody {
        margin-left: 10px;
        padding-right: 50px;
        display: flex;
        flex-direction: column;

        p {
          margin: 0px;
          background-color: #eef1f4;
          padding: 15px 15px;
          border-radius: 15px 15px 15px 0px;
          font-size: 14px;
          position: relative;
          color: #0e1114;
        }

        time {
          font-size: 12px;
          color: #616c76;
          font-weight: 400;
          position: relative;
          top: 2px;
          float: left;
          text-transform: uppercase;
          text-align: left;
        }
      }
    }

    &.chatRight {
      justify-content: end;

      .chatMessageBody {
        margin-right: 10px;
        padding-left: 50px;
        display: flex;
        flex-direction: column;

        p {
          margin: 0px;
          background-color: #d3e6e5;
          padding: 15px 15px;
          border-radius: 15px 15px 0px 15px;
          font-size: 14px;
          position: relative;
          color: #0e1114;
        }

        time {
          font-size: 12px;
          color: #616c76;
          font-weight: 400;
          position: relative;
          top: 2px;
          float: right;
          text-transform: uppercase;
          text-align: right;
        }
      }
    }
  }
}

.chatSubmit {
  padding: 15px 24px;
  background: #ffff;
  position: fixed;
  // position: sticky;
  bottom: 0;
  width: calc(49% - 120px);

  .chatSubmiBody {
    display: flex;
    align-items: flex-end;
    background: #eff0f5;
    padding: 8px 10px;
    border-radius: 10px;

    .typeForm {
      margin: 0px 5px;
      width: 100%;

      // position: relative;
      .sendImageBox {
        // transform: translate(-18%, 0%);
        position: relative;
        left: -46px;
        background-color: #fff;
        border-radius: 6px;

        // width: 100%;
        &.imageUploadLoadingBox {
          display: none;

          .loader {
            position: absolute;

          }

          &::before {
            content: "";
            position: absolute;
            top: 0;
            height: 100%;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.500);
            border-radius: 6px;
            // z-index: 100;
          }

        }

        .sendImage {
          // height: 100px;
          // width: 150px;
          // object-fit: cover;
          // border-radius: 4px;
          width: auto;
          max-width: 120px;
          height: 68px;
          border-radius: 6px;
          background: #fdfdfd;
          padding: 10px;
          padding-bottom: 0px;
          padding-left: 12px;
          object-fit: contain;
        }

        .type_nameFile {
          padding: 3px 0px;
          padding-left: 4px;
          padding-right: 4px;
          font-size: 10px;
          display: flex;
          align-items: center;
          justify-content: center;

          .nameFiles {
            overflow: hidden;
            -o-text-overflow: ellipsis;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: horizontal;
            text-wrap: nowrap;
            max-width: 37px;
          }
        }

        .crossButton {
          position: absolute;
          top: 2px;
          left: 6px;
          border: none;
          padding: 0;
          background: none;

          :global(svg) {
            background-color: rgb(224, 238, 251);
            font-size: 19px;
            border-radius: 50%;
            padding: 4px;
          }
        }
      }




      :global(.form-control) {
        border: none;
        font-size: 14px;
        padding: 8px 0px;
        background: #eff0f5;

        &:focus {
          box-shadow: none;
        }
      }
    }

    .actionBtns {
      display: flex;

      button {
        border: none;
        font-size: 18px;
        color: #1ab192;
        width: 23px;
        height: 36px;
        background: transparent;
        display: flex;
        align-items: center;
        border-radius: 50px;
        justify-content: center;
        margin-right: 5px;
        position: relative;
        padding: 0;

        svg {
          path {
            stroke: #1ab192;
          }
        }

        input[type="file"] {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          cursor: pointer;
        }
      }
    }

    .submitAction {
      display: flex;

      button {
        border: none;
        font-size: 25px;
        color: #7f8699;
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        border-radius: 50px;
        justify-content: center;
        position: relative;

        &.activeTyping {
          svg {

            color: #3E8575;

          }
        }

        svg {
          path {
            stroke: #959dad;
          }
        }
      }
    }
  }
}

.detailBox {
  position: relative;

}

// .chatBoxHide {
//   // display: none;
// }

.detailBody {
  overflow-x: hidden;
  height: calc(100vh - 65px);

  &::-webkit-scrollbar {
    width: 1px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 50px;
  }

  padding: 0;

  .heading {
    padding: 15px 20px;
    height: 70.71px;
    width: 100%;
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 5;
    background-color: #eff0f5;

    h5 {
      margin: 0;
      font-weight: 600;
      font-size: 20px;
      color: #525c62;
    }
  }

  .connection {
    display: flex;

    .connectionBox {
      background-color: #fff;
      padding: 20px 0;
      width: 100%;
      border-left: 4px solid #eff0f5;
      border-right: 4px solid #eff0f5;

      .imageBox {
        position: relative;
        text-align: center;

        .role {
          position: absolute;
          bottom: 0;
          right: 50%;
          transform: translate(50%, 50%);
          padding: 2px 7px;
          border-radius: 6px;
          letter-spacing: 0.02px;
          background-color: #1ab192;
          color: #fff;
          font-weight: 600;
          font-size: 14px;
        }

        .connectImage {
          width: 130px;
          height: 150px;
          object-fit: cover;
          border-radius: 12px;
          margin: 0 auto;
          display: block;
          overflow: hidden;
          border: 1px solid linear-gradient(#65beac, #3e8575);;
        }
      }

      .imageLogoBox {
        width: 130px;
        height: 150px;
        background-image: linear-gradient(#65beac, #3e8575);
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        border-radius: 8px;
        margin: 0 auto;

        span {
          font-size: 30px;
        }
      }

      h3 {
        text-align: center;
        font-weight: 600;
        font-size: 16px;
        color: #132326;
        margin-top: 18px;
      }

      .callBox {
        background-image: linear-gradient(#65beac, #3e8575);
        height: 36px;
        width: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        border-radius: 50%;
        margin-top: 16px;

        img {
          cursor: pointer;
        }
      }
    }
  }

  .dateHeading {
    padding: 15px 20px;
    background-color: #d3e7e5;
    overflow: hidden;

    h5 {
      font-weight: 600;
      font-size: 14px;
      color: #1e8c76;
      margin: 0;
    }
  }
}

.appointment {
  padding: 15px 20px;
  margin-bottom: 70px;

  h2 {
    font-weight: 700;
    font-size: 22.69px;
    color: black;
    margin: 0;
    margin-bottom: 3px;
  }

  p {
    font-weight: 400;
    margin: 0;
    margin-bottom: 12px;
    font-size: 17.02px;
    color: black;
  }

  .services {
    display: flex;
   flex-wrap: wrap;
    gap: 8px;

    span {
      font-weight: 400;
      color: #fff;
      font-size: 16px;
      background-image: linear-gradient(#65beac, #3e8575);
      box-shadow: 0 1px 4px 0 #00000040;
      padding: 4px 10px;
      border-radius: 5px;
    }
  }

  .action {
    height: 41px;
    border-radius: 6px;
    background-color: #fff;
    display: flex;
    align-items: center;
    padding: 0 10px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.149);
    margin-top: 15px;

    a {
      text-decoration: none;
      color: inherit;

      span {
        color: #7f8699;
        font-weight: 400;
        font-size: 16px;
      }
    }
  }
}



.price {
  background-color: #fff;
  padding: 15px 20px;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-left: 4px solid #eff0f5;
  border-right: 4px solid #eff0f5;
  width: 100%;
  border-top: 4px solid #eff0f5;

  span {
    font-weight: 400;
    font-size: 18px;

    &.amount {
      font-weight: 700;
      font-size: 24px;
    }
  }
}

.noAppoint_yet {
  text-align: center;

  .icon {
    background-color: #65beaceb;
    display: inline-flex;
    width: 87px;
    height: 87px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-bottom: 12px;

    :global(svg) {
      font-size: 39px;
      color: #fff;
    }
  }

  h4 {
    font-size: 16px;
    color: #767474;
    line-height: normal;
  }
}

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/
@media only screen and (min-width: 768px) and (max-width: 991px) {}