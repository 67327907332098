.uploadData {
    h5 {
        font-size: 20px;
        font-weight: 600;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            display: flex;
            color: #7f8699;
            line-height: 25px;
            font-size: 14px;

            span {
                width: 100px;
            }

            strong {
                color: #1d1d1d;
                font-weight: 600;
            }

            a {
                color: var(--Main);
                text-decoration: none;
            }
        }
    }
}

.price_categorySelect {
    display: flex;
    gap: 10px;
    align-items: flex-start;

    .displayBox {
        p {
            background-color: #eff0f5;
            border: 1px solid #eff0f5;
            padding: 10px 13px;
            font-size: 14px;
            border-radius: 8px;
            height: 45px;
            padding: 9px 10px;
            margin-bottom: 0px;
            overflow: hidden;
            -o-text-overflow: ellipsis;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            line-height: 27px;
        }
    }

    .eyeModal_relative {
        position: relative;

        p {
            padding-right: 34px;
        }

        .content_modal {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translate(-50%, -50%);
            cursor: pointer;

            :global(svg) {
                font-size: 18px;
            }
        }
    }

    :global(.form-label) {
        font-size: 13px;
        font-weight: 600;
        line-height: 15px;
        height: 24px;
    }

    :global(.form-select),
    :global(.form-control) {
        font-size: 13px;
        padding: 9px 10px;
        background-size: 11px;
        height: auto;
    }

    .duration_withPrice {
        display: flex;
        background: #eff0f5;
        width: 100%;
        padding: 8px 0px;
        border-bottom: 20px;
        align-items: center;
        border-radius: 6px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);

        :global(.form-control) {
            width: 50%;
            background-color: transparent;
            border: none;
            padding: 0;
            text-align: center;
            box-shadow: none;
        }
    }

    .edit_delete_btn {
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            display: flex;
            margin-top: 0px;
            margin-bottom: 14px;

            li {
                :global(.btn) {
                    padding: 6px 6px;

                    img {
                        width: 15px;
                    }
                }
            }
        }
    }

    :global(.plusBtn) {
        background: #479382 !important;
        color: #fff !important;
        padding: 0px 0px;
        width: 45px;
        height: 40px;
        margin-top: 34px;
        font-size: 12px;
    }

    .imageBox {
        height: 100%;
        position: relative;

        .deleteICon {
            position: absolute;
            top: 0;
            right: 0;
            height: 24px;
            width: 24px;
            padding: 2px;
            background-color: #fff;
            border-radius: 50%;
            cursor: pointer;
            border: 1px solid rgb(150, 147, 147);
        }

        .uploadLabel {
            position: absolute;
            bottom: -2px;
            right: -2px;

            height: 30px;
            width: 30px;

            .uploadVector {
                height: 100%;
                width: 100%;
                background-color: #fff;
                border-radius: 50%;
                padding: 4px;
                cursor: pointer;
            }
        }

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
}

.addBtn {
    color: #fff !important;
    background-image: linear-gradient(#65beac, #3e8575) !important;
    border: none;
    padding: 5px 19px;
    border-radius: 3px;
    margin-bottom: 15px;
    min-width: 47px;
}

.saveButton {
    margin-top: 12px;

    button {
        color: #fff !important;
        background-image: linear-gradient(#65beac, #3e8575) !important;
        border: none;
    }
}

.addedSocial {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
        a {
            display: flex;
            color: var(--Main);
            text-decoration: none;
            align-items: center;
            margin-bottom: 10px;

            .iconBox {
                font-size: 20px;
                margin-right: 10px;
                line-height: 0;
            }
        }
    }
}

.studentsCount {
    display: flex;
    align-items: center;
    justify-content: space-between;

    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            display: flex;
            color: #7f8699;
            line-height: 25px;
            font-size: 14px;

            strong {
                color: #1d1d1d;
                font-weight: 600;
            }
        }
    }
}

// .checkbox {
//     position: relative;
//     cursor: pointer;
//     -webkit-user-select: none;
//     -moz-user-select: none;
//     -ms-user-select: none;
//     user-select: none;
//     padding-left: 25px;

//     &:hover input~.checkmark {
//         background-color: #fff;
//         border: 1px solid #3e8575;
//     }

//     input {
//         opacity: 0;
//         margin-right: 10px;
//         position: absolute;

//         &:checked~.checkmark {
//             border: 1px solid #3e8575;
//         }

//         &:checked~.checkmark:after {
//             display: block;
//         }
//     }

//     .checkmark {
//         position: absolute;
//         top: 0px;
//         left: 0;
//         height: 25px;
//         width: 25px;
//         background-color: #fff;
//         border-radius: 6px;
//         border: 1px solid #3e8575;

//         &:after {
//             content: "";
//             position: absolute;
//             display: none;
//             left: 9px;
//             top: 5px;
//             width: 6px;
//             height: 12px;
//             border: solid #3e8575;
//             border-width: 0 2px 2px 0;
//             -webkit-transform: rotate(45deg);
//             -ms-transform: rotate(45deg);
//             transform: rotate(45deg);
//         }

//     }
// }

.listUl {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    margin-top: 3px;

    li {
        margin-right: 20px;
    }
}

/*=======================================================
  //////////////RESPONSIVE MEDIA QUERY START///////////////
  =======================================================*/
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .uploadData {
        ul {
            li {
                line-height: 35px;
            }
        }
    }
}