@import "variables.module.scss";

body {
  background-color: var(--BodyBackground) !important;
  margin: 0;
  padding: 0;
  font-size: 16px;
  color: #222;
  width: 100%;
  height: 100%;
  font-family: "Open Sans" !important;
  font-weight: normal;
  font-style: normal;
  letter-spacing: -0.5px;
  overflow: hidden;
}
.switch {
  position: relative;
  vertical-align: top;
  width: 100px;
  height: 27px;
  background: #fff;
  cursor: pointer;
  box-sizing: content-box;
}
.buttonflexDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  .threeButton {
    background-color: #fff;
    border-radius: 12px;
    margin: 0;

    li {
      padding: 3px;
      margin: 0 !important;

      .btn {
        color: #509f8e;
        background-color: #fff;
        font-size: 14px;
        font-weight: 500;

        border: none;
        border-radius: 12px;
        padding: 10px 30px;

        &.active {
          background-image: linear-gradient(#65beac, #3e8575) !important;
          color: #fff !important;
        }
      }
    }
  }
}
.switchInput {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  box-sizing: content-box;
}

.switchLabel {
  position: relative;
  display: block;
  height: inherit;
  font-size: 12px;
  background-image: linear-gradient(#eceeef, #eceeef);

  box-sizing: content-box;
  border: 1px solid #e4dcdc;
  overflow: hidden;
  border-radius: 5px;
}

.switchLabel:before,
.switchLabel:after {
  position: absolute;
  top: 50%;
  margin-top: -0.5em;
  line-height: 1;
  -webkit-transition: inherit;
  -moz-transition: inherit;
  -o-transition: inherit;
  transition: inherit;
  box-sizing: content-box;
}

.switchLabel:before {
  content: attr(data-off);
  right: 11px;
  color: #aaaaaa;
  text-shadow: 0 1px rgba(255, 255, 255, 0.5);
}

.switchLabel:after {
  content: attr(data-on);
  left: 11px;
  color: #ffffff;
  text-shadow: 0 1px rgba(0, 0, 0, 0.2);
  opacity: 0;
}

.switchInput:checked ~ .switchLabel {
  // background-image: linear-gradient(var(--Main), var(--Main));
  background-image: linear-gradient(#65beac, #3e8575);
}

.switchInput:checked ~ .switchLabel:before {
  opacity: 0;
}

.switchInput:checked ~ .switchLabel:after {
  opacity: 1;
}

.switchHandle {
  position: absolute;
  top: 3px;
  left: 3px;
  width: 23px;
  height: 23px;
  background: #d8d8d8;
  border-radius: 5px;
}

.switchHandle:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -6px 0 0 -6px;
  width: 12px;
  height: 12px;
  background: #d8d8d8;
  border-radius: 6px;
}

.switchInput:checked ~ .switchHandle {
  left: 74px;
  background: #fff;
}

.switchInput:checked ~ .switchHandle:before {
  background: #fff;
}

/* Transition
========================== */
.switchLabel,
.switchHandle {
  transition: All 0.3s ease;
  -webkit-transition: All 0.3s ease;
  -moz-transition: All 0.3s ease;
  -o-transition: All 0.3s ease;
}

.onOff_switch {
  width: 60px;
  background-color: transparent;

  .switchLabel {
    border-radius: 30px;
    // background: linear-gradient(98.3deg,
    //     rgb(199, 42, 42) 10.6%,
    //     rgb(255, 0, 0) 97.7%);
    background: linear-gradient(
      98.3deg,
      rgb(215, 17, 17) 10.6%,
      rgb(215, 10, 10) 97.7%
    );
    border: none;
  }

  .switchHandle {
    border-radius: 50%;
    top: 3px;
    width: 21px;
    height: 21px;
  }

  .switchLabel:before {
    content: "Off";
    color: #fff;
    right: 10px;
  }

  .switchLabel:after {
    content: "On";
  }

  .switchInput:checked ~ .switchHandle {
    left: 36px;
  }
}

img {
  display: inline-block !important;
}

// .checkbox {
//   position: relative;
//   cursor: pointer;
//   -webkit-user-select: none;
//   -moz-user-select: none;
//   -ms-user-select: none;
//   user-select: none;
//   padding-left: 25px;

//   &:hover input ~ .checkmark {
//     background-color: #f3f1f1;
//     border: 1px solid #d8d8d8;
//   }

//   input {
//     opacity: 0;
//     margin-right: 10px;
//     position: absolute;

//     &:checked ~ .checkmark {
//       background-color: var(--Main);
//       border: 1px solid var(--Main);
//     }
//     &:checked ~ .checkmark:after {
//       display: block;
//     }
//   }
//   .checkmark {
//     position: absolute;
//     top: 3px;
//     left: 0;
//     height: 18px;
//     width: 18px;
//     background-color: #fff;
//     border-radius: 3px;
//     border: 1px solid #d8d8d8;

//     &:after {
//       content: "";
//       position: absolute;
//       display: none;
//       left: 6px;
//       top: 2px;
//       width: 5px;
//       height: 10px;
//       border: solid white;
//       border-width: 0 2px 2px 0;
//       -webkit-transform: rotate(45deg);
//       -ms-transform: rotate(45deg);
//       transform: rotate(45deg);
//     }
//   }
// }
.checkbox {
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-left: 25px;

  &:hover input ~ .checkmark {
    background-color: #fff;
    border: 1px solid #3e8575;
  }

  input {
    opacity: 0;
    margin-right: 10px;
    position: absolute;

    &:checked ~ .checkmark {
      border: 1px solid #3e8575;
    }

    &:checked ~ .checkmark:after {
      display: block;
    }
  }

  .checkmark {
    position: absolute;
    top: 0px;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #fff;
    border-radius: 6px;
    border: 1px solid #3e8575;

    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 9px;
      top: 5px;
      width: 6px;
      height: 12px;
      border: solid #3e8575;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}

// .radio {
//   position: relative;
//   cursor: pointer;
//   -webkit-user-select: none;
//   -moz-user-select: none;
//   -ms-user-select: none;
//   user-select: none;

//   &:hover input ~ .checkmark {
//     background-color: #f3f1f1;
//     border: 1px solid #d8d8d8;
//   }

//   input {
//     opacity: 0;
//     margin-right: 5px;

//     &:checked ~ .checkmark {
//       background-color: var(--Main);
//       border: 1px solid var(--Main);
//     }
//     &:checked ~ .checkmark:after {
//       display: block;
//     }
//   }
//   .checkmark {
//     position: absolute;
//     top: 5px;
//     left: 0;
//     height: 17px;
//     width: 17px;
//     background-color: #eee;
//     border-radius: 50%;
//     border: 1px solid #d8d8d8;

//     &:after {
//       content: "";
//       position: absolute;
//       display: none;
//       left: 4px;
//       top: 4px;
//       width: 7px;
//       height: 7px;
//       border-radius: 50%;
//       background: white;
//     }
//   }
// }

.radio {
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 7px 0px 0px 40px;

  &:hover input ~ .checkmark {
    background-color: #f3f1f1;
    border: 1px solid #f3f1f1;
  }

  input {
    opacity: 0;
    margin-right: 5px;
    position: absolute;

    &:checked ~ .checkmark:after {
      background-image: linear-gradient(#65beac, #3e8575);
    }

    &:checked ~ .radio {
      font-weight: 800;
    }
  }

  .checkmark {
    position: absolute;
    top: 5px;
    left: 0;
    height: 28px;
    width: 28px;
    background-image: linear-gradient(to right, #f3f4f9, #f3f4f9);
    border-radius: 50%;
    border: 1px solid #eee;
    box-shadow: -4px -2px 4px 0px #fff, 4px 2px 8px 0px #d1d9e6;

    &:after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      width: 20px;
      transform: translate(-50%, -50%);
      height: 20px;
      border-radius: 50%;
      background: white;
      background-image: linear-gradient(to right, #ffffff, #dae0ee);
      box-shadow: -4px -2px 4px 0px #d1d9e6, 4px 2px 8px 0px #fff;
    }
  }
}

.onoff {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .slider {
      background-color: var(--Main);
    }
    &:focus + .slider {
      box-shadow: 0 0 1px #2196f3;
    }
    &:checked + .slider:before {
      transform: translateX(26px);
    }
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 25px;

    &:before {
      position: absolute;
      content: "";
      height: 20px;
      width: 20px;
      left: 2px;
      bottom: 3px;
      background-color: #fff;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 50%;
    }
  }
}

.pageWrapper {
  margin-left: 65px;
  margin-top: 0px;
  overflow: hidden;
  height: calc(100vh - 47px);
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #cdd2de;
    border-radius: 50px;
  }

  &.pageWrapperOpen {
    margin-left: 245px;
  }

  .pageTitle {
    z-index: 99;
    background: #fff;
    margin: 0;
    padding: 15px 20px;

    .bridSection {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: center;
      color: #7f8699;

      li {
        font-size: 14px;

        a {
          color: var(--Main);
          text-decoration: none;
          color: #7f8699;
        }
        span {
          color: var(--Main);
        }
      }
    }

    .titleInfo {
      display: flex;
      align-items: center;

      .icon {
        margin-right: 10px;
        filter: invert(0%) sepia(80%) saturate(210%) hue-rotate(290deg)
          brightness(390%) contrast(10%);
        height: 25px;
      }
      .titleInfoBody {
        h5 {
          margin-bottom: 1px;
          font-size: 16px;
          font-weight: 600;
        }
        p {
          margin: 0px;
          font-size: 13px;
          color: #7f8699;
        }
      }

      :global(.btn) {
        background: linear-gradient(0deg, #f3f4f9, #f3f4f9),
          linear-gradient(0deg, #efeef3, #efeef3),
          linear-gradient(0deg, #efeef3, #efeef3),
          linear-gradient(
            180deg,
            rgba(239, 238, 243, 0.27) 0%,
            rgba(239, 238, 243, 0.29) 100%
          ),
          #dde1eb;
        box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);
        border-radius: 5px;
        border: none;
        color: #7f8699;
        min-width: 80px;
        height: 35px;
        line-height: 35px;
        padding: 0px;
        font-size: 14px;
        margin-left: 10px;

        &.back {
          min-width: 35px;
          height: 35px;
          font-size: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0px 10px 0px 0px;
        }
      }
    }

    .actionTop {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: end;

      li {
        :global(.btn) {
          background: linear-gradient(0deg, #f3f4f9, #f3f4f9),
            linear-gradient(0deg, #efeef3, #efeef3),
            linear-gradient(0deg, #efeef3, #efeef3),
            linear-gradient(
              180deg,
              rgba(239, 238, 243, 0.27) 0%,
              rgba(239, 238, 243, 0.29) 100%
            ),
            #dde1eb;
          box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);
          border-radius: 5px;
          border: none;
          color: #7f8699 !important;
          min-width: 80px;
          height: 35px;
          line-height: 35px;
          padding: 0px;
          font-size: 14px;
          margin-left: 10px;

          &.info {
            min-width: 35px;
            height: 35px;
            font-size: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }

  .pageWrapperInside {
    background-color: var(--BodyBackground);
    padding: 0px 20px;

    &.setWidth {
      max-width: 70rem;
      margin: 0 auto;
    }

    :global(.card) {
      background-color: transparent;
      border: none;

      :global(.card-body) {
        padding: 0px;
        margin-bottom: 0px;

        .contentBox {
          border-radius: 8px;
          padding: 20px 20px;
          margin-bottom: 30px;
          border: 1px solid #cdd2de;
          background: linear-gradient(0deg, #f1f3f6, #f1f3f6),
            linear-gradient(0deg, #f3f4f9, #f3f4f9), #ffffff;
        }
      }
    }
  }
}

.formField {
  margin-bottom: 10px;
  &.inside {
    position: relative;
    :global(.form-control) {
      padding-left: 35px;
    }
    img {
      position: absolute;
      top: 42px;
      left: 10px;
    }
  }

  :global(.form-label) {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px;

    &.labelHide {
      display: block;
      opacity: 0;
    }
  }
  :global(.input-group) {
    .icon {
      position: absolute;
      left: 18px;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 9;
      font-size: 19px;
      color: #7f8699;
    }
    :global(.form-control),
    :global(.form-select) {
      padding-left: 35px;
    }
  }
  :global(.react-tel-input) {
    font-family: "Open Sans" !important;

    :global(.form-control) {
      background-color: #ffffff;
      border: 1px solid #e3e7ee;
      border-radius: 8px;
      // height: 40px;
      padding: 0px 12px;
      padding-left: 60px;
      font-size: 14px;
      width: 100%;
      background: #fff;
      border: 1px solid #7f8699;
      box-shadow: none;
      font-size: 14px;
      padding: 5px 15px;
      padding-left: 40px;

      &:focus {
        background-color: #fff;
        border: 1px solid #65beac;
        border: 1px solid #65beac;
        box-shadow: none;
        outline: none;
      }
    }
  }
  :global(.form-control),
  :global(.form-select) {
    background-color: #ffffff;
    border: 1px solid #e3e7ee;
    border-radius: 8px;
    height: 40px;
    padding: 0px 12px;
    font-size: 14px;
    line-height: 40px;
    // background: #fff;
    border: 1px solid #7f8699;
    box-shadow: none;
    padding-left: 40px;
    font-size: 14px;
    height: 45px;
    padding: 10px 15px;

    &:focus {
      background-color: #fff;
      border: 1px solid #65beac;
      border: 1px solid #65beac;
      box-shadow: none;
      outline: none;
    }
  }
}

.uploadForm {
  text-align: center;

  .fileUpload {
    position: relative;
    background: #ffffff;
    border-radius: 10px;
    border: 1px solid #e3e7ee;
    width: 100%;
    height: 130px;
    overflow: hidden;

    &.pageUpload {
      height: 140px;
      width: 40%;
    }

    input[type="file"] {
      position: absolute;
      width: 100%;
      height: 100%;
      cursor: pointer;
      opacity: 0;
    }
    .icon {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  :global(.form-label) {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px;
  }
}

:global(.btn) {
  &.btnDisabled {
    align-items: center;
    border: none;
    border-radius: 8px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.149);
    display: flex;
    font-size: 16px;
    font-weight: 600;
    height: 45px;
    justify-content: center;
    min-width: 250px;
    padding: 0 15px;
    text-decoration: none;
    width: 100%;
    background: linear-gradient(#65beac, #3e8575);
    &:hover {
      opacity: 0.8;
    }
    &.cancel {
      background-image: none;
      color: rgb(58, 56, 56);
      background: linear-gradient(
          0deg,
          rgba(83, 81, 81, 0.05),
          rgba(0, 0, 0, 0.05)
        ),
        #e6eaf0;
    }
  }
  &.editIcon {
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(0deg, #f3f4f9, #f3f4f9),
      linear-gradient(0deg, #efeef3, #efeef3),
      linear-gradient(0deg, #efeef3, #efeef3),
      linear-gradient(
        180deg,
        rgba(239, 238, 243, 0.27) 0%,
        rgba(239, 238, 243, 0.29) 100%
      ),
      #dde1eb;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    border: none;
    color: #7f8699 !important;
    padding: 0px;
    font-size: 14px;
    margin-left: 10px;
    float: right;
  }
}

.tabbingList {
  :global(.nav) {
    :global(.nav-item) {
      :global(.nav-link) {
        min-width: 130px;
        background: #fff;
        color: #adaeb2;
        text-align: center;
        border: 1px solid #cdd2de;
        margin-right: 10px;
        border-radius: 8px 8px 0px 0px;
        padding: 8px 15px;
        font-size: 14px;
        position: relative;
        top: 1px;

        &:global(.active) {
          background: linear-gradient(0deg, #f1f3f6, #f1f3f6),
            linear-gradient(0deg, #f3f4f9, #f3f4f9), #ffffff;
          border-bottom: 1px solid #f1f3f6;
          color: var(--Main);
          font-weight: 500;
        }
      }
    }
  }
  .contentBox {
    border-radius: 0px 0px 8px 8px !important;

    .tabTitle {
      h5 {
        font-size: 18px;
        margin-bottom: 5px;
        font-weight: 600;
      }
      p {
        font-size: 14px;
        color: Grey;
      }
    }
  }
}

.dataNotFound {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  span {
    width: 50px;
    height: 50px;
    background: #e6eaf0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    border-radius: 50px;

    img {
      height: 25px;
    }
  }
  .setupBtn {
    background: linear-gradient(0deg, #f3f4f9, #f3f4f9),
      linear-gradient(0deg, #efeef3, #efeef3),
      linear-gradient(0deg, #efeef3, #efeef3),
      linear-gradient(
        180deg,
        rgba(239, 238, 243, 0.27) 0%,
        rgba(239, 238, 243, 0.29) 100%
      ),
      #dde1eb;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    border: none;
    color: var(--Main) !important;
    min-width: 80px;
    height: 35px;
    line-height: 35px;
    padding: 0px 20px;
    font-size: 14px;
  }
  p {
    margin: 10px 0px;
    color: #7f8699;
    font-size: 14px;
  }
}

.tabbingMenu {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    button {
      background: transparent;
      border: none;
      color: gray;
      position: relative;
      padding: 3px 0px;
      margin-right: 25px;
      border-bottom: 2px solid transparent;

      &:focus {
        border-bottom: 2px solid var(--Main);
        color: var(--Main);
      }

      &.active {
        border-bottom: 2px solid var(--Main);
        color: var(--Main);
      }
    }
  }
}

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/

@media (max-width: 767px) {
  .pageWrapper {
    margin-left: 0px;

    .pageTitle {
      padding: 20px 20px;
      display: block;

      .rowTitleLeft {
        margin-bottom: 20px;

        h5 {
          font-size: 30px;
          font-weight: 300;
          margin-bottom: 0px;
          color: #4d4d4d;

          .back {
            color: var(--Main2);
            text-decoration: none;
            margin-right: 20px;
          }
        }
      }

      .rowTitleRight {
        overflow-x: scroll;
        display: flex;
        padding-bottom: 10px;

        :global(.btn) {
          min-width: auto;
        }
      }
    }

    .pageWrapperInside {
      padding: 20px 20px;
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
}
@media only screen and (max-width: 5520px) and (min-width: 768px) {
}

@media only screen and (min-width: 1200px) and (max-width: 1279px) {
}

@media (min-width: 1200px) {
}

/* =======================================================
  //////////////RESPONSIVE MEDIA QUERY END///////////////
  ======================================================= */
