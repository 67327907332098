@font-face {
    font-family: 'Open Sans';
    src: url('../src/fonts/OpenSans-Bold.woff2') format('woff2'),
        url('../src/fonts/OpenSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../src/fonts/OpenSans-ExtraBold.woff2') format('woff2'),
        url('../src/fonts/OpenSans-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../src/fonts/OpenSans-ExtraBoldItalic.woff2') format('woff2'),
        url('../src/fonts/OpenSans-ExtraBoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../src/fonts/OpenSans-BoldItalic.woff2') format('woff2'),
        url('../src/fonts/OpenSans-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../src/fonts/OpenSans-Light.woff2') format('woff2'),
        url('../src/fonts/OpenSans-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../src/fonts/OpenSans-LightItalic.woff2') format('woff2'),
        url('../src/fonts/OpenSans-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../src/fonts/OpenSans-Italic.woff2') format('woff2'),
        url('../src/fonts/OpenSans-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../src/fonts/OpenSans-MediumItalic.woff2') format('woff2'),
        url('../src/fonts/OpenSans-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../src/fonts/OpenSans-Medium.woff2') format('woff2'),
        url('../src/fonts/OpenSans-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../src/fonts/OpenSans-Regular.woff2') format('woff2'),
        url('../src/fonts/OpenSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../src/fonts/OpenSans-SemiBold.woff2') format('woff2'),
        url('../src/fonts/OpenSans-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../src/fonts/OpenSans-SemiBoldItalic.woff2') format('woff2'),
        url('../src/fonts/OpenSans-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}




@font-face {
    font-family: 'Saira';
    src: url('../src/fonts/Saira-Black.woff2') format('woff2'),
        url('../src/fonts/Saira-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Saira';
    src: url('../src/fonts/Saira-BlackItalic.woff2') format('woff2'),
        url('../src/fonts/Saira-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Saira';
    src: url('../src/fonts/Saira-Bold.woff2') format('woff2'),
        url('../src/fonts/Saira-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Saira';
    src: url('../src/fonts/Saira-BoldItalic.woff2') format('woff2'),
        url('../src/fonts/Saira-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Saira';
    src: url('../src/fonts/Saira-ExtraBold.woff2') format('woff2'),
        url('../src/fonts/Saira-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Saira';
    src: url('../src/fonts/Saira-ExtraLightItalic.woff2') format('woff2'),
        url('../src/fonts/Saira-ExtraLightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Saira';
    src: url('../src/fonts/Saira-ExtraBoldItalic.woff2') format('woff2'),
        url('../src/fonts/Saira-ExtraBoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Saira';
    src: url('../src/fonts/Saira-ExtraLight.woff2') format('woff2'),
        url('../src/fonts/Saira-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Saira';
    src: url('../src/fonts/Saira-Italic.woff2') format('woff2'),
        url('../src/fonts/Saira-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Saira';
    src: url('../src/fonts/Saira-Light.woff2') format('woff2'),
        url('../src/fonts/Saira-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Saira';
    src: url('../src/fonts/Saira-Medium.woff2') format('woff2'),
        url('../src/fonts/Saira-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Saira';
    src: url('../src/fonts/Saira-LightItalic.woff2') format('woff2'),
        url('../src/fonts/Saira-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Saira';
    src: url('../src/fonts/Saira-MediumItalic.woff2') format('woff2'),
        url('../src/fonts/Saira-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Saira';
    src: url('../src/fonts/Saira-Regular.woff2') format('woff2'),
        url('../src/fonts/Saira-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Saira';
    src: url('../src/fonts/Saira-SemiBold.woff2') format('woff2'),
        url('../src/fonts/Saira-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Saira';
    src: url('../src/fonts/Saira-SemiBoldItalic.woff2') format('woff2'),
        url('../src/fonts/Saira-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Saira';
    src: url('../src/fonts/Saira-Thin.woff2') format('woff2'),
        url('../src/fonts/Saira-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Saira';
    src: url('../src/fonts/Saira-ThinItalic.woff2') format('woff2'),
        url('../src/fonts/Saira-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

